import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";

function HeroSlider({ headertitle, Subheader }) {
    const { rpdata } = useContext(GlobalDataContext);
    return (
      <>
        <div className="pic-wrapper">
          <figure
            className="pic-1"
            style={{ background: `url("${rpdata?.stock?.[22]}")` }}
          ></figure>
          <figure
            className="pic-2"
            style={{ background: `url("${rpdata?.stock?.[25]}")` }}
          ></figure>
          <figure
            className="pic-3"
            style={{ background: `url("${rpdata?.stock?.[24]}")` }}
          ></figure>
          <figure
            className="pic-4"
            style={{ background: `url("${rpdata?.stock?.[23]}")` }}
          ></figure>
          <div className="z-10 relative w-4/5 mx-auto pt-[350px] pb-[100px] md:pb-[150px] md:pt-[380px]  ">
            <div className="w-full flex flex-col justify-center items-center">
            <h1 className="text-white text-center">
             Services
            </h1>
            </div>
            <div className="w-1/2"></div>
          </div>
        </div>
        
      </>
    );
  }
  
  export default HeroSlider;