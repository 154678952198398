import React from "react";
// import { GlobalDataContext } from "../context/context";
/* import TransparentHeader from "../components/global/TransparentHeader"; */
import ContentServices from "../components/Services/ContentServices";
import BaseLayout from "../components/global/BaseLayout";
import HeroSlider from "../components/Services/HeroSlider";


function Services() {
  // const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="Services">
      <div className="md:max-w-full w-full">
        {/* <TransparentHeader
          headertitle="Services"
          Subheader="Services"
          bgimg={`${rpdata?.stock?.[10]}`}
        /> */}
        <HeroSlider
        />
        
        <ContentServices/>
      </div>
    </BaseLayout>
  );
}

export default Services;
